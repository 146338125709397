//const gql = require('gql-query-builder');

module.exports = {
    signatures:
        `
        __schema {
            mutationType {
                name
                fields {
                    name
                    args {
                        name
                        defaultValue
                        type {
                            kind
                            name
                            ofType {
                                kind
                                name
                                ofType {
                                    kind
                                    name
                                    ofType {
                                        kind
                                        name
                                        ofType {
                                            kind
                                            name
                                            ofType {
                                                kind
                                                name
                                                ofType {
                                                    kind
                                                    name
                                                    ofType {
                                                        kind
                                                        name
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                queryType {
                    name
                    fields {
                        name
                        args {
                            name
                            defaultValue
                            type {
                                kind
                                name
                                ofType {
                                    kind
                                    name
                                    ofType {
                                        kind
                                        name
                                        ofType {
                                            kind
                                            name
                                            ofType {
                                                kind
                                                name
                                                ofType {
                                                    kind
                                                    name
                                                    ofType {
                                                        kind
                                                        name
                                                        ofType {
                                                            kind
                                                            name
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
    
            }
        `,

    simulatedCatalog: [
        {
            name: "Gameloft",
            storeId: " xxx",
            onoroff: false, alive: true,
            status: "B: UAT",
            lambdaFunction: "/gameloft", // Each supplier should be associated with a lambda function with a graphQL interface 
            region: "Global", type: "Games", clients: "???",
        },
        {
            name: "DinoMarket",
            storeId: " xxx",
            onoroff: false, alive: true,
            status: "B: UAT",
            lambdaFunction: "/dinomarket", // Each supplier should be associated with a lambda function with a graphQL interface 
            region: "Indonesia", type: "Products", clients: "BNI",
        },
        {
            name: "CatchMe.LK",
            storeId: " xxx",
            onoroff: false, alive: true,
            status: "A: Production (List Only)",
            lambdaFunction: "/catchme_lk", // Each supplier should be associated with a lambda function with a graphQL interface 
            region: "SriLanka", type: "Products", clients: "BML",
        },
        {
            name: "GiiftMarketplace",
            storeId: "DemoStore",
            onoroff: false, alive: true,
            status: "A: Production",
            lambdaFunction: "/giiftmarketplace", // Each supplier should be associated with a lambda function with a graphQL interface 
            region: "Global", type: "Gift Cards", clients: "Global",
        },
        {
            name: "Justlounge",
            storeId: " xxx",
            onoroff: false, alive: true,
            status: "A: Production",
            lambdaFunction: "/justlounge", // Each supplier should be associated with a lambda function with a graphQL interface 
            region: "Africa", type: "Products", clients: "Visa",
        },
        {
            name: "21stAvenue",
            storeId: " xxx",
            onoroff: false, alive: true,
            status: "A: Production",
            lambdaFunction: "/21stavenue", // Each supplier should be associated with a lambda function with a graphQL interface 
            region: "Africa", type: "Products", clients: "Visa",
        },
        {
            name: "Menakart",
            storeId: " xxx",
            onoroff: false, alive: true,
            status: "A: Production",
            lambdaFunction: "/menakart", // Each supplier should be associated with a lambda function with a graphQL interface 
            region: "Africa", type: "Products", clients: "Visa",
        },
        {
            name: "Giiftbox",
            storeId: "GBXStore",
            onoroff: false, alive: true,
            status: "A: Production",
            lambdaFunction: "/giiftbox", // Each supplier should be associated with a lambda function with a graphQL interface 
            region: "Global", type: "Merchant Offers", clients: "-",
        },
        {
            name: "Scandid",
            storeId: " xxx",
            onoroff: false, alive: true,
            status: "A: Production",
            lambdaFunction: "/scandid", // Each supplier should be associated with a lambda function with a graphQL interface 
            region: "Africa / GCC", type: "eVouchers", clients: "-",
        },
        {
            name: "Xoxoday",
            storeId: " xxx",
            onoroff: false, alive: true,
            status: "B: UAT",
            lambdaFunction: "/xoxoday", // Each supplier should be associated with a lambda function with a graphQL interface 
            region: "Africa / GCC", type: "eVouchers", clients: "-",
        },
        {
            name: "HoliBob (std version)",
            storeId: "xx",
            onoroff: false, alive: true,
            status: "A: Production",
            lambdaFunction: "/holibobstd", // Each supplier should be associated with a lambda function with a graphQL interface 
            region: "GCC", type: "experiences", clients: "AHB Visa BRI BNI",
        },
        {
            name: "Proto Virto",
            storeId: " xxx",
            onoroff: false, alive: true,
            status: "A: Production",
            lambdaFunction: "/protovirto", // Each supplier should be associated with a lambda function with a graphQL interface 
            region: "<inform region>", type: "evouchers", clients: "<inform clients>",
        },
        { name: "JDID", storeId: " xxx", onoroff: false, alive: true, region: "GCC", type: "goods", status: "B: Uat", clients: "Mashreq BRI BNI" },
        { name: "Groupon", storeId: " xxx", onoroff: false, alive: true, region: "West", type: "coupons", status: "C: In nego", clients: "QCB Visa BRI BNI" },
        { name: "LetsTango", storeId: " xxx", onoroff: false, alive: true, region: "Indonesia", type: "goods", status: "D: --", clients: "Axa Visa " },
        { name: "allCards", storeId: " xxx", onoroff: false, alive: true, region: "GCC", type: "vouchers", status: "E: Unknown", clients: "HSBC" },
    ]
    //console.log ("🏄 SupplierList is : " + JSON.stringify (this.supplierList))
}
<template>
  <div style="height: 100%; width: 100%; overflow: hidden; position: absolute">
   
    <h1 style="height: 50px; width: 100%">Supplier world</h1>
   
   
    <div style="margin-left: 8px; margin-right: 8px; height: calc(100% - 50px); display: flex; flex-direction: column;
         flex-wrap: wrap; align-content: flex-start; overflow: auto">
      <v-data-table style="width: 100%; flex: 1 0 50%; "
          dense
          :headers= supplierHeader
          :items="supplierList"
          item-key="name"
          single-select
          class="elevation-2 table100"
          @click:row="(item, deco) => activate(item, deco)"
          @pagination="testall">

        <template v-slot:item.showvc="{ item, index }">
          <v-btn v-if="true"
            :loading="loadings[index]"
            @click="vc.getCatalog (item).then (extractGoods)" class="ma-2" >
              {{ '🔎 ' }}
          </v-btn>
        </template>

        <template v-slot:item.onoroff="{ item }">
          <v-chip class="ma-2"
            :color="item.onoroff?'green':'red'"
            dark
          >
            {{ item.onoroff }}
          </v-chip>
        </template>

        <template v-slot:item.alive="{ item, index }">
          <v-btn v-if="true /*item.onoroff*/"
            :loading="loadings[index]"
            :color="item.alive?'primary':'pink'"
            @click="callLambda (item, selectedQMname).then (extractGoods)" class="ma-2" >
              {{ item.alive?'✅ TRY IT':'No lambda'}}
          </v-btn>
        </template>

        <!-- template v-slot:item.loading="{ item, index  }">
          <v-btn 
            loading="loadings[index]"
            :color="item.alive?'primary':'pink'"
            @click="e => e" >
              {{ loadings[index]?'✅ YO':'YA'}}
          </v-btn>
        </template -->


      </v-data-table>
    </div>


    <v-dialog id="widgetor" v-model="bookerOnOff" width="50%" class="dialogTweak" content-class="dialogContentTweak">
        <component :is="bookerComponentName"
          :seed="stringified (good)"
        >
          <v-progress-circular indeterminate size="64" ></v-progress-circular>
        </component>
    </v-dialog>

    <v-dialog v-model="showgoods" width="90%" height="90%" class="dialogTweak" content-class="dialogContentTweak">
      <div style="position: relative; display: flex; flex-wrap: wrap; height: 100%;     overflow: hidden;">
        <div style="flex: 0 0 100%; height: 72px; background-color: #9dbbf1; display: flex; justify-content: space-between; align-items: center">
          <v-select
            style="flex: 0 0 220px; margin: 8px; max-height: 40px"
            :value="selectedQMname"
            @input="v => {selectedQMname = v; resetArgumentValues()}" 
            :items="querynames" 
            label="active function"
            outlined
            dense
          ></v-select>

          <component v-for="(arg, index) in selectedArgs" :is="argComponentName (arg.type.name)" :key="arg.name"
            :label="arg.name"
            @keydown.enter="callAsShown()"
            outlined dense
            height= "40px"
            v-model="selectedArgValues[index]"
            class="arginput"
            :style="(arg.type.name != 'Boolean')?'flex: 1 0 150px':' flex: 1 1 100px'"
          ></component>
          <v-btn
            :loading="loadings[supplierIndex]"
            rounded large
            style="font-size: 24px; max-height: 40px; margin-left: 64px; margin-right: 16px; "
            @click.stop="callAsShown()"
            >
            🛍️ <span style="font-size: 14px; ; margin-left: 8px">Call</span>
          </v-btn>        
          <v-snackbar v-model="snackbaronoff" :timeout="5000" >
            {{ snacktext }} 
            <template v-slot:action="{ attrs }">
              <v-btn
                color="blue"
                text
                v-bind="attrs"
                @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </div>
        <div  style="display: flex; flex-wrap: wrap; position: relative;
                      height: calc(100% - 72px);
                      width: 100%;
                      overflow: scroll;
                      background-color: pink;
                      ">
          <v-card v-for="good in goods" :key="good.index"
            :loading="loading"
            class="ma-md-2 mx-auto my-2 pa-4"
            width="200px"
            height="180px"
            style="overflow: scroll"
            >
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
              ></v-progress-linear>
            </template>
            <v-img
              @click="loadBookingWidget(supplier.name, good)"
              style="cursor: pointer"
              height="60"
              :src="good.previewImage?good.previewImage.url:good.imgSrc"
            ></v-img>

            <v-card-title style="font-size: 11px;
                                padding: 0px;
                                height: 73px;
                                line-height: 13px;
                                overflow: hidden;
                                text-align: left;
                                word-break: break-word;">
                {{good.name}}
            </v-card-title>

            <v-card-text max-height="400" >
              <!-- v-row
                align="center"
                class="mx-0"
              >
                <v-rating
                  :value="4.5"
                  color="amber"
                  dense
                  half-increments
                  readonly
                  size="14"
                ></v-rating>

                <div class="grey--text ml-4" >
                  4.5 (413)
                </div>
              </v-row>

              <div class="my-4 subtitle-1">
                {{good.guidePriceCurrency}} • {{good.categoryList?good.categoryList.nodes[0]?good.categoryList.nodes[0].name:'':''}}
              </div -->

              <div    style="height: 100px" class="overflow-y-auto">{{descriptionFor (good)}}</div>
            </v-card-text>

            <v-divider class="mx-4"></v-divider>

            <!-- v-card-title>Tonight's availability</v-card-title>

            <v-card-text>
              <v-chip-group
                v-model="timeSelection"
                active-class="deep-purple accent-4 white--text"
                column
              >
                <v-chip>5:30PM</v-chip>

                <v-chip>7:30PM</v-chip>

                <v-chip>8:00PM</v-chip>

                <v-chip>9:00PM</v-chip>
              </v-chip-group>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="deep-purple lighten-2"
                text
                @click="book (good.id)"
              >
                Book
              </v-btn>
            </v-card-actions -->
          </v-card>
          <div v-if="!!rawResponse" style="background-color: pink; width: 100%; height: 100%">
            {{ rawResponse }}
          </div>
        </div>
      </div>
    </v-dialog>

    <v-dialog v-model="showWhat" width="40%" class="dialogTweak" content-class="dialogContentTweak">
      <h1>Try  ordering it? Dynamic component?</h1>
      <v-text-field dense outline placeholder="argument 1" style="margin: 8px"> </v-text-field>
      <v-text-field dense outline placeholder="argument 2" style="margin: 8px"> </v-text-field>
      <v-text-field dense outline placeholder="argument 3" style="margin: 8px"> </v-text-field>
      <div style="dispay: flex; flex-direction: column">
          <v-btn v-for="query in supplierQMs" :key="query.name" style="margin: 8px"> {{query.name}} </v-btn>
      </div>
    </v-dialog>

  </div>
</template>

<script>

import Vue from 'vue'
import qb from "./querybuilder.js"
import {vcStage as vc} from '@giift/vc-connector'

//const inProduction = (process.env.NODE_ENV == 'production')
const awsprodUrl = "https://d1m7l1ir0g.execute-api.ap-south-1.amazonaws.com" //'https://ncibay8gq9.execute-api.us-east-2.amazonaws.com'
//const googprodUrl = 'https://us-central1-giiftsuppliergql.cloudfunctions.net'

//const devUrl = 'http://localhost:4025/giiftsuppliergql/us-central1'

const baseUrl = awsprodUrl //inProduction?awsprodUrl:devUrl

export default {
  name: 'SupplierDashboard',
  data: function () {
    return {
      loading: false,
      loadings: new Array(1000), 
      bookerOnOff: false,
      bookerComponentName: "",
      showgoods: false,
      showWhat: false,
      snackbaronoff: false,
      snacktext: "Message for the one snackbar",
      timeSelection: 1,
      selectedArgValues: [],
      selectedQMname: vc.QM.name, // Query or Mutation name currently selected
      supplierQMs: [vc.QM],
      goods: [],
      rawResponse: null,
      supplierHeader: [
        { text: 'Supplier name',
          align: 'start',
          sortable: true,
          value: 'name'},
        { text: '', value: 'showvc'},
        { text: 'Connected', value: 'onoroff' },
        { text: 'Status', value: 'alive'},
        { text: 'Region', value: 'region' },
        { text: 'Main product type', value: 'type' },
        { text: 'Status', value: 'status' },
        { text: 'Client list', value: 'clients' },
        ],
      supplierList: [],
      supplier: {}, // active list item (tells us what to mount)
      good: {name: 'unknown'}, // active good
      vc: vc
      }
    },
  mounted () {
    this.loading = true
    this.fetchCatalog ()
      .then ( suppliers => {
        this.loading = false
        const sl = suppliers.filter (supplier => supplier?true:false)
        sl.forEach (supplier => {supplier.lambdaFunction = '/' + supplier.name.toLowerCase()})
        console.log (`🏓 sl ${JSON.stringify (sl.map (a => a.lambdaFunction))}`)
        this.supplierList = sl
        })
    },
  computed: {
    supplierIndex: function () {return this.supplierList.indexOf (this.supplier)},
    querynames: function () {
      let sps = this.supplierQMs
      if (!sps) return [vc.QM.name]
      return [vc.QM.name, ...sps.map (q => q.name)]
      },
    selectedSignature: function () {
      let i = this.querynames.indexOf (this.selectedQMname)
      if (i > 0) return this.supplierQMs[i-1]
      else return {name: this.selectedQMname, args: []}
      },
    selectedArgs: function () {
      let result = [...this.selectedSignature.args]
      //let emptyarg = {name: ""}
      //for (let i = result.length; i < 4; i++)
      //  result[i] = emptyarg
      return result
      }
    },
  methods: {
    fetchCatalog () { 
      return Promise.resolve  (qb.simulatedCatalog) 
      },
    argComponentName (type) {
      if (type === "Boolean") return "v-checkbox"
      return "v-text-field"
      },
    resetArgumentValues () {
        this.selectedSignature.args.forEach ((arg, i) => {
          let df = arg.defaultValue
          if (df) df = df.replace(/^"(.*)"$/, '$1') // Ensuring that "blahblah" becomes blahblah
          else df= ""
          if (arg.type.name == "Boolean") df = df == 'true'
          this.selectedArgValues [i] = df
          })
      },
    stringified (good) {
      try {return JSON.stringify (good)} catch (e) {return ""}
      },
    callAsShown () {
      let query = this.selectedQMname

      if (query === vc.QM.name)
        vc.getCatalog ({...this.supplier, name: this.supplier.storeId}).then (c => {
          //Vue.set (this.loadings, i, false)
          this.extractGoods(c)
          })
      else {
        let args = this.selectedSignature.args
        let l = args.length
        for (let i = 0; i < l; i++) {
          let argValue = this.selectedArgValues [i]
          if (args[i].type.name === "String" || (args[i].type.ofType && args[i].type.ofType.name === "String"))
            argValue = '\\"' + argValue + '\\"'
          query += ` ${i?', ':'('} ${args[i].name}: ${argValue} ${i===l-1?')':''}`
          }
        //console.log ("🏄 Calling query: " + query)
        this.callLambda (this.supplier, query).then (this.extractGoods)
        }
      },
    testall () {
      this.supplierList.forEach (supplier => this.testLambdaAndSupplier (supplier))
      },
    activate (item, deco) {
      //console.log ("🏄 Activating " + item.name)
      if (deco) deco.select(true)
      this.supplier = item
      this.extractSchema()
      },

    testLambdaAndSupplier (supplier) {
      if (!supplier) return
      supplier.onoroff = false

      if (supplier.lambdaFunction) {
        try {
          this.callLambda (supplier, 'testLambda')
            .then (r => {
        //console.log ("HAHAGANA")
              if (r.data && (r.data.testLambda == 'alive')) {
                supplier.onoroff = true
                return true}})
            .then (r => r?this.callLambda (supplier, 'testit'):{data: 'connection issue'})
            .then (r => {
              supplier.alive = r.data && (r.data.testit == 'alive')
              })
          }
        catch (e) {console.error (e)}
        }
      },

    callLambda (supplier, query) {
      if (!supplier) new Promise(() => `no supplier for ${i}`, e => e)
      let i = this.supplierList.indexOf (supplier)
      Vue.set (this.loadings, i, true)
      let lambdaUrl = supplier.lambdaFunction
      //console.log (`🏄 Calling ${i}-th supplier: ` + supplier.name + " for " + lambdaUrl)
      if (!lambdaUrl) {
        Vue.set (this.loadings, i, false)
        return new Promise(() => 'no lambda function registered', e => e)
        }
      return fetch (baseUrl + lambdaUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: `{"query": "query {             ` + query + `}" }` 
                })
              .then (r => {
                Vue.set (this.loadings, i, false)
                return r.json() })
              .catch (e => {
                Vue.set (this.loadings, i, false)
                return new Promise(() => `Lambda "mal"function: ${e}`, e => e)})
        },
    
    extractGoods (r) {
      //console.log ('🏄 Got these goods: ' + JSON.stringify (r))
      this.rawResponse = null
      if (!r )
        this.goods = [ {name: "No response from Supplier!"}]
      else {
        let payload = r.data?Object.values(r.data)[0]:{items: []}
        this.rawResponse = payload
        this.goods = []
        try {
          if ('string' === typeof payload) payload = JSON.parse (payload)
          if (payload) {
            let inventory = payload.Inventory || payload
            // Any response with an inventory will be rendered as tiles:
            if (inventory) {
              this.goods = inventory.Items || inventory.items
              this.rawResponse = null
              }
            }
          }
        catch (e) {console.warn ("Unparsable response: " + e)}
        }
      this.showgoods = true
      },

    extractSchema () {
      let quequery = qb.signatures.replace(/\n/g, '') // so we don't worry about it in querybuilder.js
      this.callLambda (this.supplier, quequery)
            .then (schm => {
                      try {
                          schm = schm.data.__schema
                          let qms = [...schm.queryType.fields, ...schm.mutationType.fields]
                          this.supplierQMs = qms
                          //console.log ('🏄 Got this schema: ' + qms.map (q => q.name + ' ' + (q.args?' (' + q.args.map (arg =>  arg.name + '/' + (arg.type.ofType?arg.type.ofType.name:'') ).join (', ') + ')':'') ).join ('\n') )
                          } catch (e) {console.error (e)}
                    }, e => console.error (e))
      },
  
    replaceByCustomElement (parent, v_name, src, cb) {
      let script_name = 'scriptFor_'+v_name
      // dynamically insert script (if doesn't already exist)
      if (!document.getElementById (script_name)) {
        const script = document.createElement ('script')
        script.src = src
        script.id = script_name
        script.onload = cb
        console.log (" 🎟️ Loading a script: " + src)
        document.body.appendChild (script)
        }
      const element = document.createElement (v_name)
      parent.replaceChild (element, parent.firstChild) // add for now. TOODO: make it override
      },

    loadBookingWidget (name, good) {
      this.good = good
      if (good.redirectUrl)
        window.open (good.redirectUrl, good.name)
        // "resizable=yes,height=600")
      else if (good.metas.landingUrl)
        window.open (good.metas.landingUrl, good.name, "resizable=yes,height=600")
      else {
        let v_name = 'v-primo' // Should be 'v-'+name , // bound to component "is" that is a TOODO
        let widgetFile = v_name+'.js' 
        let parent = document.getElementById ("widgetor")
        this.replaceByCustomElement (parent, v_name, widgetFile, () => {this.bookerComponentName = v_name})
        this.bookerOnOff = true
        }
      },
    descriptionFor (good) {
      const descriptionInProperty = good.properties?.find (p => p.name === 'AboutBrandFeed')
      return good.description || descriptionInProperty?.value
      }        
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
  .arginput {
    margin-top: 0px !important;
    max-height: 40px;
    margin-left: 8px
    }
  .dialogTweak {
    align-items: flex-start !important;
    }
  .dialogContentTweak {
    background-color: lightblue;
    overflow: hidden;
    height: 90%;
    }
    
  .actionButton {
    margin: 8px;
    height: 30px
    }

  .v-data-table-header {
    font-size: 36px !important;
    text-transform: uppercase!important;
    background-color: rgb(199, 233, 242) !important;
    }
 .v-input--selection-controls {
    margin-top: 0px;
    padding-top: 0px;
    }
  .v-dialog__content {
    align-items: flex-start
    }
  .v-dialog>* {
      width: auto !important;
    }
</style>

import Vue from 'vue'
Vue.config.productionTip = false
Vue.config.devtools = false // avoids the annoying "For a better development experience..."
window.Vue = Vue // So that I can import Web components made with Vuejs

import App from './App.vue'

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
Vue.use(Vuetify)
const opts = {}
const vuetify = new Vuetify(opts)

new Vue( { vuetify, render: h => h(App)} ).$mount('#app')

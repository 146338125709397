<template>
<v-app>
<img src="https://d27504pnkoride.cloudfront.net/uploads/2019/03/logo.png" width="80px"
    style="position: absolute; x=0; y=0; padding: 16px">
    <SupplierDashboard/>
</v-app>
</template>

<script>
import SupplierDashboard from './components/SupplierDashboard.vue'

export default {
  name: 'App',
  components: {
    SupplierDashboard
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  width: 100%;
}
</style>
